import axios from "axios";
import { BASE_URL } from "../configs";
import JSZip from "jszip";
import FormData from "form-data";
import { axiosInstanceCollab } from "./config";

const URL = BASE_URL + "/candidatOffre";

export const addCandidatOffre = (form, id, files) => {
  let data = new FormData();
  form.id_offre = id;
  data.append("candidatOffre", JSON.stringify(form));

  var zip = new JSZip();
  files.forEach((e, i) => {
    const data = e.file.replace(/^data:application\/\w+;base64,/, "");
    zip.file(`${e.name}-${i}-file.pdf`, data, { base64: true });
  });

  return zip.generateAsync({ type: "Blob" }).then(function (content) {
    data.append("file", content, "file_");
    return axios.post(`${URL}`, data);
  });
};

export const getCandidatByOffre = (id) => {
  return axiosInstanceCollab.get(`${URL}/${id}`);
};

export const editStatusCandidatOffreById = (id, status) => {
  const data = {
    status_candidat_offre: status,
  };
  return axios.put(`${URL}/${id}`, data);
};

export const deleteCandidatOffre = (id) => {
  return axios.delete(`${URL}/${id}`);
};
